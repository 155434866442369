import React, { Component } from 'react';
import { Spin } from 'antd';
import AvocadoModerator from '../../../api/avocado.moderator';

import './sso.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../../context/userContext';

interface IUser {
  firstName: string;
  lastName: string;
}
export default class Verification extends Component<any, any> {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }
  componentDidMount(): void {
    const [userState, setUser] = this.context;
    if (userState) {
      if (userState.permissions.role === 'admin') {
        this.props.history.push('/admin');
      } else if (userState.permissions.role === 'brand') {
        this.props.history.push('/partner');
      } else {
        this.props.history.push('/moderator');
      }
    }
  }
  async componentDidUpdate(): Promise<void> {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const ssoFirstName = urlParams.get('ssoFirstName');
    const ssoLastName = urlParams.get('ssoLastName');

    if (token) {
      await this.verify(token, ssoFirstName, ssoLastName);
    } else {
      this.props.history.push('/');
    }
  }

  async verify(token: string, ssoFirstName: string, ssoLastName: string) {
    const [userState, setUser] = this.context;
    sessionStorage.setItem('avocado-token', token);
    const result = await AvocadoModerator.getUserByToken();

    if (result && result.status === 200) {
      const resultBody: { firstName?: string; lastName?: string } = result.body;

      const user: IUser = {
        ...result.body,
        firstName: ssoFirstName ?? resultBody?.firstName,
        lastName: ssoLastName ?? resultBody?.lastName,
      };
      sessionStorage.setItem('avocado-user', JSON.stringify(user));
      setUser(user);

      if (result.body.permissions.role === 'wladmin') {
        this.props.history.push('/admin');
      } else if (result.body.permissions.role === 'brand') {
        this.props.history.push('/partner');
      } else {
        this.props.history.push('/moderator');
      }
    }
  }

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 28 }} spin />;

    return (
      <React.Fragment>
        <div className="verification">
          <Spin indicator={antIcon} className="spinner" tip="Loading..." />
        </div>
      </React.Fragment>
    );
  }
}
