import { SearchOutlined } from "@ant-design/icons";
import { Select, Row, Col, Button, Form, DatePicker } from "antd";

import { Formik } from "formik";
import React, { useContext } from "react";
import moment from "moment";
import { PartnerContext } from "../../../context/partnerContext";

const Filters = (props) => {
  const { Option } = Select;
  const { onSubmit, showPartners, filters } = props;
  const { RangePicker } = DatePicker;
  const [partners, setPartners] = useContext(PartnerContext);

  // console.log(filters.filter);
  const formValues = {
    partner:
      filters.filter && filters.filter.brandId ? filters.filter.brandId : "ALL",
    startDate:
      filters.filter && filters.filter.startDate
        ? filters.filter.startDate
        : null,
    endDate:
      filters.filter && filters.filter.endDate ? filters.filter.endDate : null,
  };

  return (
    <div className="subheader">
      <Row className="container">
        <Col span={3}>
          <h3>Dashboard</h3>
        </Col>
        <Col span={21}>
          <Row justify="end">
            <Col span={24}>
              <Formik
                initialValues={formValues}
                onSubmit={(values, actions) => {
                  console.log({ values, actions });
                  onSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, setFieldValue }) => (
                  <Form onSubmitCapture={handleSubmit} colon={false}>
                    <Row style={{ float: "right" }}>
                      {showPartners ? (
                        <Form.Item
                          className="inline-form-item"
                          label="Partners"
                        >
                          <Select
                            defaultValue="ALL"
                            showSearch
                            value={values.partner}
                            onChange={(e) => {
                              setFieldValue("partner", e);
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                 // @ts-ignore
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Option value="ALL">All</Option>
                            {partners.map((partner, i) => (
                              <Option key={i} value={partner._id}>
                                {partner.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : null}
                      <Form.Item className="inline-form-item" label="Date">
                        <RangePicker
                          value={
                            values.startDate && values.endDate
                              ? [
                                  moment(values.startDate),
                                  moment(values.endDate),
                                ]
                              : [null, null]
                          }
                          ranges={{
                            Today: [moment(), moment()],
                            Yesterday: [
                              moment().subtract(1, "day"),
                              moment().subtract(1, "day"),
                            ],
                            "This Week": [
                              moment().startOf("week"),
                              moment().endOf("week"),
                            ],
                            "Last Week": [
                              moment().subtract(1, "week").startOf("week"),
                              moment().subtract(1, "week").endOf("week"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                          }}
                          onChange={(e) => {
                            console.log(e);
                            if (e) {
                              let startDate = moment(e[0]).format("YYYY/MM/DD");
                              let endDate = moment(e[1]).format("YYYY/MM/DD");
                              console.log(startDate, endDate);
                              setFieldValue("startDate", startDate);
                              setFieldValue("endDate", endDate);
                            } else {
                              setFieldValue("startDate", null);
                              setFieldValue("endDate", null);
                            }
                          }}
                          style={{
                            marginRight: "0.6rem",
                          }}
                        />
                        <Button htmlType="submit" type="default">
                          Apply
                        </Button>
                      </Form.Item>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
