import React, { Component, useRef } from "react";
import {
  Col,
  Pagination,
  Row,
  Skeleton,
  Table,
  Tag,
  Avatar,
  message,
  Button,
   Card
} from "antd";
import {
  MobileOutlined,
  MailOutlined
} from '@ant-design/icons';
import logo from "../../assets/logoGreen.png";
import ReactToPrint from 'react-to-print';
import AvocadoAdmin from "../../api/avocado.admin";
import Item from "antd/lib/list/Item";

  const { Meta } = Card;
export default class PrintInvoice extends Component<any, any> {
  
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      paging: {
        totalDocs: 0,
      },
      invoice:{}
    };
    
    this.isAdmin = this.isAdmin.bind(this);
  }

  isAdmin(): boolean {
    return this.props.match.path === "/admin/invoice";
  }

  componentDidMount() {
    window.document.title = "Shorelight - Invoice";
    this.getInvoice();
  }

 

  async getInvoice() {
    this.setState({ isLoading: true });
    try {
      // message.loading("Loading...");
      let no = this.props.match.params.no
      console.log(no);
      
      let result = await AvocadoAdmin.getInvoice(no);
      this.setState({ invoice: result.body, isLoading:false });
      console.log(result);
      
    } catch (error) {}
    this.setState({ isLoading: false });
  }
  render() {
    const {invoice} = this.state;
    let componentRef; 
    return (
      <React.Fragment>
        {this.state.isLoading ? (
                <div className="container"><Skeleton active></Skeleton></div>
              ) : (
                <div>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <div className="container" style={{width:"100%", display:'flex', justifyContent:'flex-end', padding:15}}><Button className="btn" href="#">Print</Button></div>;
          }}
          content={() => componentRef}
        />
        <div className="container" style={{display:'flex', justifyContent:'center'}} ref={el => (componentRef = el)}>
          <div style={{margin:'20px 100px', width:700}}>
            <Row>
              <Col span={12}>
                <div  style={{fontSize: 28, fontWeight:'bold'}}>Invoice</div>
                <div  style={{fontSize: 12}}> Avacardo Aprill 2021 to June 2021</div>
                <Row>
                  <Col span={16} style={{marginTop:30}}>
                    <div  style={{fontSize: 12, fontWeight:600}}>From</div>
                     {invoice.from && invoice.from.split(',').map( (item, index) =>{return<div  style={{fontSize: 12}}>{item}{index !== invoice.from.split(',').length-1 ?",":""}</div> })}
                    

                    <div  style={{marginTop:20, fontSize: 12, fontWeight:600}}>To</div>
                    {invoice.to && invoice.to.split(',').map( (item, index) =>{return<div  style={{fontSize: 12}}>{item}{index !== invoice.to.split(',').length-1 ?",":""}</div> })}
                    
                  </Col>
                  <Col span={8} style={{marginTop:30}}>
                    <div  style={{fontSize: 12, fontWeight:600}}>Date</div>
                    <div  style={{fontSize: 12}}> {invoice.date}</div>

                    <div  style={{marginTop:20,fontSize: 12, fontWeight:600}}>Invoice Number</div>
                    <div  style={{fontSize: 12}}> {invoice.no? 'IN-'+invoice.no:'-'}</div>

                    <div  style={{marginTop:20, fontSize: 12, fontWeight:600}}>Invoice due</div>
                    <div  style={{fontSize: 12}}> {invoice.dueDate}</div>
                  </Col>
                </Row>
                </Col>
              <Col span={12} style={{display:'flex', justifyContent:'flex-end', alignItems: 'flex-start'}}><img style={{width: 200}} src={logo}/></Col>
            </Row>
            <Row style={{marginTop:30, borderBottom:'1px solid #d5d3d3'}}>
              <Col span={10}><div  style={{fontSize: 12, fontWeight:600}}>Description</div></Col>
              <Col span={4}><div  style={{fontSize: 12, fontWeight:600 }}>Quantity</div></Col>
              <Col span={5}><div  style={{fontSize: 12, fontWeight:600 , textAlign:'right'}}>Rate</div></Col>
              <Col span={5}><div  style={{fontSize: 12, fontWeight:600 , textAlign:'right'}}>Total</div></Col>
            </Row>
            <Row style={{marginTop:30, borderBottom:'1px solid #d5d3d3'}}>
              <Col span={10}><div  style={{fontSize: 12, fontWeight:600}}>{invoice.description}</div></Col>
              <Col span={5}><div  style={{fontSize: 12, fontWeight:600}}>{invoice.qty}</div></Col>
              <Col span={5}><div  style={{fontSize: 12, fontWeight:600, textAlign:'right'}}>{invoice.rate}</div></Col>
              <Col span={4}><div  style={{fontSize: 12, fontWeight:600 , textAlign:'right'}}>{invoice.total}</div></Col>
            </Row>
           
            <Row style={{marginTop:30}}>
              <Col span={9}><div  style={{fontSize: 12, fontWeight:600}}></div></Col>
              <Col span={9} style={{borderBottom:'1px solid #d5d3d3'}}><div  style={{fontSize: 12, fontWeight:600}}>Subtotal</div></Col>
              <Col span={6}  style={{borderBottom:'1px solid #d5d3d3'}}><div  style={{fontSize: 12, fontWeight:600 , textAlign:'right'}}>{invoice.total}</div></Col>
            </Row>
            <Row style={{marginTop:30}}>
              <Col span={9}><div  style={{fontSize: 12, fontWeight:600}}></div></Col>
              <Col span={9} style={{borderBottom:'1px solid #d5d3d3'}}><div  style={{fontSize: 12, fontWeight:600}}>paid</div></Col>
              <Col span={6}  style={{borderBottom:'1px solid #d5d3d3'}}><div  style={{fontSize: 12, fontWeight:600 , textAlign:'right'}}>{invoice.paid}</div></Col>
            </Row>
            <Row style={{marginTop:30}}>
              <Col span={9}><div  style={{fontSize: 12, fontWeight:600}}></div></Col>
              <Col span={9}  style={{borderBottom:'1px solid #d5d3d3'}}><div  style={{fontSize: 12, fontWeight:600, textTransform:'uppercase'}}>Total Due ({invoice.currency})</div></Col>
              <Col span={6}  style={{borderBottom:'1px solid #d5d3d3'}}><div  style={{fontSize: 12, fontWeight:600 , textAlign:'right'}}>{invoice.due}</div></Col>
            </Row>

            <Row style={{marginTop:30, borderTop:'1px solid black', padding:10}}>
              <div  style={{fontSize: 12, fontWeight:600 }}><MobileOutlined /> +94114337094 | <MailOutlined /> billing@surge.global</div>
            </Row>
          </div>
        </div>
        </div>)}
      </React.Fragment>
    );
  }
}
