import axios from 'axios';

const baseURL = () => {
  const apiUrl = `${process.env.REACT_APP_API_PARTNER}/`;
  return apiUrl;
  // TODO env verification on start
};

const getInstance = () => {
  const token = sessionStorage.getItem('avocado-token');
  return axios.create({
    headers: { Authorization: `Bearer ${token}` },
  });
};

const handleResponse = (response) => {  
  
  if (response.data.status === 307 && response.data.body === 'billing') {
    window.location.href = process.env.baseUrl + 'partner/billing';
  }
  return {
    body: response && response.data ? response.data.body : null,
    errors: response && response.data ? response.data.errors : {},
    status: response && response.status ? response.status : 500,
    message: response && response.data ? response.data.message : '',
    paging: response && response.data ? response.data.paging : null,
  };
};

const handleError = (errorObject) => {
  if (
    errorObject.response.data.status === 307 &&
    errorObject.response.data.body === 'billing'
  ) {
    window.location.href = 'partner/billing';
  }
  if (
    errorObject.response.data.body.status === 401 &&
    errorObject.response.data.body.code === 'invalid_token'
  ) {
    sessionStorage.clear();
    window.location.reload();
  }
  const data = errorObject.response
    ? errorObject.response.data
    : { data: { status: 500, message: errorObject.message } };
  throw new Error(data.message);
};

const AvocadoAdmin = {
  signIn: (user) => {
    let url = `${baseURL()}api/auth/sign-in`;
    return axios.post(url, user).then(handleResponse).catch(handleError);
  },
  getMembers: (filters?) => {
    let url = `${baseURL()}api/membership/brand/all`;
    return getInstance()
      .get(url, { params: filters })
      .then(handleResponse)
      .catch(handleError);
  },
  getMembersById: (filters, id) => {
    let url = `${baseURL()}api/membership/brand/${id}`;
    return getInstance()
      .get(url, { params: filters })
      .then(handleResponse)
      .catch(handleError);
  },
  getRequests: (brandId) => {
    let url = `${baseURL()}api/membership/brand/${brandId}/requests`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  handleMemberRequest: (payload) => {
    let url = `${baseURL()}api/membership/request`;
    return getInstance()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  removeMember: (payload) => {
    let url = `${baseURL()}api/membership/remove`;
    return getInstance()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  deletMember: (payload) => {
    let url = `${baseURL()}api/membership/delete`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },

  getLeaderboard: (filter = null) => {
    let url = `${baseURL()}api/membership/leaderboard/brands`;
    return getInstance()
      .get(url, { params: filter })
      .then(handleResponse)
      .catch(handleError);
  },
  getLeaderboardById: (filters, id) => {
    let url = `${baseURL()}api/membership/leaderboard/brand/${id}`;
    return getInstance()
      .get(url, { params: filters })
      .then(handleResponse)
      .catch(handleError);
  },
  getContent: (filter = null) => {
    let url = `${baseURL()}api/content/brand/all`;
    return getInstance()
      .get(url, { params: filter })
      .then(handleResponse)
      .catch(handleError);
  },
  getContentById: (filters, id) => {
    let url = `${baseURL()}api/content/brand/${id}`;
    return getInstance()
      .get(url, { params: filters })
      .then(handleResponse)
      .catch(handleError);
  },
  createBrand: (payload) => {
    let url = `${baseURL()}api/brand`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  removeBrand: (id) => {
    let url = `${baseURL()}api/brand/${id}`;
    return getInstance().delete(url).then(handleResponse).catch(handleError);
  },
  getAllBrands: (filter = null) => {
    let url = `${baseURL()}api/brand?overall=true`;
    return getInstance()
      .get(url, { params: filter })
      .then(handleResponse)
      .catch(handleError);
  },
  getBrandById: (filter?, id?) => {
    let url = `${baseURL()}api/brand/${id}`;
    return getInstance()
      .get(url, { params: filter })
      .then(handleResponse)
      .catch(handleError);
  },
  updateBrand: (payload, id) => {
    let url = `${baseURL()}api/brand/${id}`;
    return getInstance()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  deleteBrand: (id) => {
    let url = `${baseURL()}api/brand/${id}`;
    return getInstance().delete(url).then(handleResponse).catch(handleError);
  },
  createModerator: (payload) => {
    let url = `${baseURL()}api/moderator`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  getModerator: (id) => {
    let url = `${baseURL()}api/moderator/${id}`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  getAllModerator: (filter = { limit: 50 }) => {
    let url = `${baseURL()}api/moderator`;
    return getInstance()
      .get(url, { params: filter })
      .then(handleResponse)
      .catch(handleError);
  },
  deleteModerator: (id) => {
    let url = `${baseURL()}api/moderator/${id}`;
    return getInstance().delete(url).then(handleResponse).catch(handleError);
  },
  getDashboardInsightsById: (filters, id) => {
    let url = `${baseURL()}api/dashboard/brand/${id}`;
    return getInstance()
      .get(url, { params: filters })
      .then(handleResponse)
      .catch(handleError);
  },
  getDashboardDataAll: (filters) => {
    let url = `${baseURL()}api/dashboard`;
    return getInstance()
      .get(url, { params: filters })
      .then(handleResponse)
      .catch(handleError);
  },
  getAllCategories: () => {
    let url = `${baseURL()}api/brand/categories`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  getPoints: () => {
    let url = `${baseURL()}api/points`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  getUploadURL: (file) => {
    let url = `${baseURL()}api/brand/image/pre-signed-url`;
    return getInstance()
      .post(url, file)
      .then(handleResponse)
      .catch(handleError);
  },
  getCampaignUploadURL: (file) => {
    let url = `${baseURL()}api/campaign/media/pre-signed-url`;
    return getInstance()
      .post(url, file)
      .then(handleResponse)
      .catch(handleError);
  },
  forgotPassword: (payload) => {
    let url = `${baseURL()}api/auth/forgot-password`;
    return axios.post(url, payload).then(handleResponse).catch(handleError);
  },
  resetPassword: (param) => {
    let url = `${baseURL()}api/auth/reset-password`;
    return axios.post(url, param).then(handleResponse).catch(handleError);
  },
  changePassword: (payload) => {
    let url = `${baseURL()}api/me/change-password`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  createCampaign: (payload) => {
    let url = `${baseURL()}api/campaign`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  getAllCampaigns: (filter = null) => {
    let url = `${baseURL()}api/campaign`;
    return getInstance()
      .get(url, { params: filter })
      .then(handleResponse)
      .catch(handleError);
  },
  getCampaignById: (id) => {
    let url = `${baseURL()}api/campaign/${id}`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  approvalCampaign: (id, payload) => {
    let url = `${baseURL()}api/campaign/${id}/approval`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  hideCampaign: (id, payload) => {
    let url = `${baseURL()}api/campaign/${id}/hide`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  updateCampaign: (id, payload) => {
    let url = `${baseURL()}api/campaign/${id}`;
    return getInstance()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  pickWinner: (id, payload) => {
    let url = `${baseURL()}api/campaign/${id}/pick-winners`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  getAllCampaignNames: (filter = null) => {
    let url = `${baseURL()}api/content/filter/campaigns`;
    return getInstance()
      .get(url, { params: filter })
      .then(handleResponse)
      .catch(handleError);
  },
  getBillingHistoryAdmin: (id) => {
    let url = `${baseURL()}api/billing/brand/${id}`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  getBillingHistoryPartner: () => {
    let url = `${baseURL()}api/billing`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  getBillingSummaryAdmin: (id) => {
    let url = `${baseURL()}api/billing/brand/${id}/summary`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  getBillingSummaryPartner: () => {
    let url = `${baseURL()}api/billing/summary`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  getAllBilling: (filter = null) => {
    let url = `${baseURL()}api/billing/all`;
    return getInstance()
      .get(url, { params: filter })
      .then(handleResponse)
      .catch(handleError);
  },
  getPayherePreAprove: () => {
    let url = `${baseURL()}api/billing/payhere/preapprove`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  blockPartner: (id, payload) => {
    let url = `${baseURL()}api/brand/${id}`;
    return getInstance()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  sendRefund: (id, payload) => {
    let url = `${baseURL()}api/billing/brand/${id}/refund`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  getCountries: () => {
    let url = `${baseURL()}api/billing/countries`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },

  updateCap: (id, payload) => {
    let url = `${baseURL()}api/billing/brand/${id}/billing-cap`;
    return getInstance()
      .put(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  getInvoice: (no) => {
    let url = `${baseURL()}api/billing/invoice/${no}`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  updateDecayStatus: (payload) => {
    let url = `${baseURL()}api/points/points-decay`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  getDecayStatus: () => {
    let url = `${baseURL()}api/points/points-decay`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  getAllNotification: () => {
    let url = `${baseURL()}api/notifications`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  markAllAsRead: () => {
    let url = `${baseURL()}api/notifications/all`;
    return getInstance().delete(url).then(handleResponse).catch(handleError);
  },
  deleteNotification: (id) => {
    let url = `${baseURL()}api/notifications/${id}`;
    return getInstance().delete(url).then(handleResponse).catch(handleError);
  },
  //api/notifications/:notificationId
};

export default AvocadoAdmin;
