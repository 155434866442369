import React, { Component } from "react";
import { Layout, Menu } from "antd";
import Header from "./Header";

declare const window: any;
export default class Design extends Component<any, any> {
  componentDidMount() {
    let profile = JSON.parse(sessionStorage.getItem("avocado-user"));
    console.log(profile);
    if (profile && profile.permissions.role !== "admin") {
      let IntercomeName;

      if (profile.permissions.role === "brand") {
        let partner = JSON.parse(sessionStorage.getItem("avocado-partner"));
        IntercomeName = partner ? partner.name : "";
      } else {
        IntercomeName = `${profile.firstName} ${profile.lastName}`;
      }

      window.intercomSettings = {
        app_id: "sxll06xy",
        name: IntercomeName,
        email: profile.email, // Email address
      };

      if (window.Intercom) {
        window.Intercom("update");
        // window.Intercom('show')
      }
    } else {
      window.Intercom("hide");
    }
  }

  render() {
    const { children, menuData, location } = this.props;

    return (
      <div className="app-area">
        <Header menuItems={menuData ? menuData : []}></Header>
        <div className="main-area">
          <div className="site-div-background">{children}</div>
        </div>
      </div>
    );
  }
}
