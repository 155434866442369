import React, { Component } from "react";
import Design from "../../common/Design";
import AdminRoutes from "./AdminRoutes";

import './admin.scss'
export default class Admin extends Component<any,any>{
  render() {

    const {match} = this.props;
    return (
      <React.Fragment>
        <Design
          menuData={[
            {
              route: "/admin",
              label: "Dashboard",
            },
            {
              route: "/admin/members",
              label: "Members",
            },
            {
              route: "/admin/content",
              label: "Content",
            },
            {
              route: "/admin/leaderboard",
              label: "Leaderboard",
            }, {
              route: "/admin/partners",
              label: "Partners",
            },
            {
              route: "/admin/campaigns",
              label: "Campaigns",
            },
            // {
            //   route: "/admin/moderators",
            //   label: "Moderators",
            // },
           
            // {
            //   route: "/admin/points",
            //   label: "Points",
            // },
            // {
            //   route: "/admin/billing",
            //   label: "Billing",
            // },
            
          ]}
        >
          <AdminRoutes></AdminRoutes>
        </Design>
      </React.Fragment>
    );
  }
}
