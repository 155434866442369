import React, { Component, createRef } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Modal,
  Input,
  DatePicker,
  Radio,
  Image,
  message,
  Space,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import moment from "moment";
import CampaignImageUpload from "../../components/CampaignImageUpload";
import AvocadoAdmin from "../../api/avocado.admin";
import { campaign } from "./dump";
import Campaign from ".";
const { TextArea } = Input;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = true; //file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}
export default class NewCampaign extends Component<any, any> {
  private formRef = createRef<any>();
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      type: "classic",
      loading: false,
      fileList: [],
      isLoading: false,
      isEdit: false,
      campaign: [],
      campaignImage: null,
      campaignCoverImage:
        "https://www.wearespider.com/wp-content/uploads/2020/02/default-image-png-9-300x200-1.png",
      rewards: [
        { label: "1st Place", name: "1stPlace", reward: "", description: "" },
        { label: "2nd Place", name: "2ndPlace", reward: "", description: "" },
      ],
    };
    this.createCampaign = this.createCampaign.bind(this);
  }

  componentDidMount() {
    console.log(this.props);

    if (this.props.match.path === "/partner/campaigns/edit/:id") {
      this.getCampaign();
      console.log();

      let cam = this.state.campaign;
      this.setState({
        isEdit: true,
      });
    }
  }
  async getCampaign() {
    this.setState({ isLoading: true });
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.getCampaignById(
        this.props.match.params.id
      );
      this.setState({
        campaign: result.body,
        paging: result.paging,
        title: result.body.title,
      });
      //
      // let rewards = [];
      // if (result.body.rewards.length() !== 0) {
      //    rewards = result.body.rewards.map((item, index) => {
      //     return { label: index+" place", name: item.title, reward: item.description };
      //   });
      // }
      // this.setState({rewards:rewards})
      this.formRef.current.setFieldsValue({
        type: result.body.type,
        title: result.body.title,
        description: result.body.description,
        rewards: result.body.rewards,
        startDate: moment(result.body.startDate),
        endDate: moment(result.body.endDate),
        photo: result.body.photo,
        campaignCoverImage: result.body.coverPhoto,
      });
      this.setState({
        campaignId: result.body._id,
        campaignImage: result.body.photo,
        campaignCoverImage: result.body.coverPhoto,
      });
    } catch (error) {}
    this.setState({ isLoading: false });
  }
  componentDidUpdate() {}
  navigate(path) {
    this.props.history.push(path);
  }
  onFinish(values: any) {
    console.log("Success:", values);
  }
  onFinishFailed(errorInfo: any) {
    console.log("Failed:", errorInfo);
  }

  handleChange = (info) => {
    console.log(info);

    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };
  changeType(e) {
    this.setState({ type: e.target.value });
  }

  addRewardValue(e, index) {
    let rewards = this.state.rewards;
    rewards[index].reward = e.target.value;
    this.setState(rewards);
  }
  addRewardDescriton(e, index) {
    let rewards = this.state.rewards;
    rewards[index].description = e.target.value;
    this.setState(rewards);
  }
  addReward() {
    let label;
    let name;
    let length = this.state.rewards.length;
    if (length === 2) {
      label = "3rd Place";
      name = "3rdPlace";
    } else {
      label = `${length + 1}th Place`;
      name = `${length + 1}thPlace`;
    }
    let rewards = this.state.rewards;
    rewards.push({ label: label, name: name, reward: "" });
    this.setState(rewards);
  }

  async createCampaign(values: any) {
    console.log(values);

    this.setState({ isLoading: true });

    try {
      if (!this.state.isEdit) {
        message.loading("Creating Campaign");
        let result = await AvocadoAdmin.createCampaign({
          type: values.type,
          brandId: "",
          title: values.title,
          description: values.description,
          startDate: values.startDate,
          endDate: values.endDate,
          rewards: values.rewards ? values.rewards : [],
          photo: values.photo,
          coverPhoto: values.campaignCoverImage,
        });

        this.setState({ isLoading: false, openModal: true });
      } else {
        let rewards = values.rewards.map((item) => {
          return {
            title: item.title,
            description: item.description,
          };
        });
        message.loading("Updating Campaign");
        let result = await AvocadoAdmin.updateCampaign(this.state.campaignId, {
          type: values.type,
          title: values.title,
          description: values.description,
          startDate: values.startDate,
          endDate: values.endDate,
          rewards: rewards,
          photo: this.state.campaignImage,
          coverPhoto: this.state.campaignCoverImage,
        });
        this.setState({ isLoading: false, openModal: true });
      }
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  render() {
    const {
      rewards,
      fileList,
      campaign,
      type,
      title,
      startDate,
      imageUrl,
      loading,
      isEdit,
    } = this.state;
    console.log(type, title, startDate);

    const uploadButton = (
      <div style={{ width: 86, paddingBottom: 20 }}>
        {loading ? (
          <LoadingOutlined />
        ) : (
          <span
            style={{
              fontSize: 40,
              color: "#ffff",
              background: "#C4C4C4",
              padding: "15px 23px",
              borderRadius: 20,
            }}
          >
            <PlusOutlined />
          </span>
        )}
      </div>
    );
    return (
      <React.Fragment>
        <Form
          ref={this.formRef}
          name="campaignForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 17 }}
          initialValues={{
            type: type,
            title: title,
            startDate: startDate,
          }}
          onFinish={this.createCampaign}
          onFinishFailed={this.onFinishFailed}
        >
          <div className="subheader">
            <Row className="container">
              <Col span={18}>
                <h3>{this.state.isEdit ? "Edit Campaign" : "New Campaign"}</h3>
              </Col>
              <Col span={6}>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={this.state.isLoading}
                >
                  {this.state.isEdit ? "update" : "submit"}
                </Button>
                <Button
                  htmlType="submit"
                  type="default"
                  onClick={() => {
                    this.props.history.push("../../partner/campaigns");
                  }}
                  style={{ marginLeft: 10 }}
                >
                  cancel
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <div style={{ marginBottom: 60 }}>
              <Row className="container">
                <Col span={12}>
                  <div style={{ marginBottom: 20 }}>
                    {/* <p style={{ fontSize: 14, fontWeight: 'normal' }}>Campaign Type</p> */}
                    <Form.Item
                      name="type"
                      label="Campaign Type"
                      rules={[
                        { required: true, message: "Please pick an item!" },
                      ]}
                    >
                      <Radio.Group
                        value={type}
                        onChange={(e) => this.changeType(e)}
                      >
                        valuePropName="checked"
                        <Radio value={"classic"}>
                          <span style={{ fontSize: 16, fontWeight: 600 }}>
                            Classic
                          </span>
                        </Radio>
                        <Radio value={"raffle"}>
                          <span style={{ fontSize: 16, fontWeight: 600 }}>
                            Raffle
                          </span>
                        </Radio>
                        <Radio value={"creator"}>
                          <span style={{ fontSize: 16, fontWeight: 600 }}>
                            Creators
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <div style={{ margin: "20px 0px" }}>
                    {/* <p style={{ fontSize: 14, fontWeight: "normal" }}>
                      Campaign Photo
                    </p> */}
                    <Form.Item
                      label="Campaign Photo"
                      name="photo"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Campaign photo is required",
                      //   },
                      // ]}
                      style={{ paddingBottom: 20 }}
                    >
                      <CampaignImageUpload
                        height="186"
                        width="269"
                        onFileUpload={(value) => {
                          this.formRef.current.setFieldsValue({
                            photo: value,
                          });
                          this.setState({ campaignImage: value });
                        }}
                      >
                        {this.state.campaignImage ? (
                          <img
                            src={this.state.campaignImage}
                            alt="avatar"
                            style={{ width: 86, height: 86, paddingBottom: 20 }}
                          />
                        ) : (
                          uploadButton
                        )}
                      </CampaignImageUpload>
                    </Form.Item>
                  </div>

                  <Form.Item
                    label="Campaign Title"
                    name="title"
                    rules={[
                      { required: true, message: "Campaign Title is required" },
                    ]}
                  >
                    <Input
                      style={{ border: "none" }}
                      value={title}
                      onChange={(value) => {
                        this.setState({ title: value });
                      }}
                    />
                  </Form.Item>
                  <Row>
                    <Col span={10} style={{ width: "100%" }}>
                      <Form.Item
                        label="Start Date"
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please select campaign start date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ border: "none" }}
                          defaultValue={null}
                          format={"DD/MM/YYYY"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        label="End Date"
                        name="endDate"
                        rules={[
                          {
                            required: true,
                            message: "Please campaign select end date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ border: "none" }}
                          defaultValue={null}
                          format={"DD/MM/YYYY"}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Campaign Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please describe about campaign",
                      },
                    ]}
                  >
                    <TextArea style={{ border: "none" }} rows={4} />
                  </Form.Item>

                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 600,
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        marginRight: 4,
                        lineHeight: 1,
                        fontFamily: "sans-serif",
                        fontSize: 16,
                        color: "#ff4d4f",
                      }}
                    >
                      *
                    </span>
                    Rewards
                  </p>
                  <Row>
                    <Col span={18}>
                      <Form.List
                        name="rewards"
                        rules={[
                          {
                            validator: async (_, rewards) => {
                              if (!rewards || rewards.length === 0) {
                                return Promise.reject(
                                  new Error("Please add at least one reward")
                                );
                              }
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {fields.map(
                              ({ key, name, fieldKey, ...restField }) => (
                                <Space
                                  key={key}
                                  style={{
                                    display: "flex",
                                    marginBottom: 8,
                                    height: "auto",
                                  }}
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, "title"]}
                                    fieldKey={[fieldKey, "title"]}
                                    label="Reward Title"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please add reward title",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Reward Title" />
                                  </Form.Item>

                                  <Form.Item
                                    {...restField}
                                    name={[name, "description"]}
                                    label="Reward Description"
                                    fieldKey={[fieldKey, "description"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please add reward descriton",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Reward Description" />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  />
                                </Space>
                              )
                            )}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={
                                  <PlusOutlined
                                    style={{ color: "#707070" }}
                                    size={24}
                                  />
                                }
                                style={{
                                  width: 40,
                                  height: 40,
                                  backgroundColor: "#FFFFFF",
                                }}
                              ></Button>
                            </Form.Item>
                            <Form.ErrorList errors={errors} />
                          </>
                        )}
                      </Form.List>
                    </Col>
                  </Row>
                  {/* {rewards.map((item, index) => {
                    return (
                      <Row>
                        <Col span={10}>
                          <Form.Item name={item.name}>
                            <p>Reward Title</p>
                            <Input
                              value={item.reward}
                              placeholder={item.label}
                              onChange={(e) => this.addRewardValue(e, index)}
                            />
                          </Form.Item>
                        </Col>••••••••
                        <Col span={10}>
                          <Form.Item name={item.name}>
                            <p>Reward Description</p>
                            <Input
                              value={item.description}
                              onChange={(e) =>
                                this.addRewardDescriton(e, index)
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                  <p>Add Reward</p>
                  <Button
                    type="default"
                    size={"middle"}
                    icon={<PlusOutlined />}
                    onClick={() => this.addReward()}
                  /> */}
                </Col>
                <Col span={12}>
                  <p>
                    <span
                      style={{
                        marginRight: 4,
                        lineHeight: 1,
                        fontFamily: "sans-serif",
                        fontSize: 16,
                        color: "#ff4d4f",
                      }}
                    >
                      *
                    </span>
                    Campaign Cover
                  </p>
                  <Image
                    style={{
                      width: 505,
                      height: 289,
                      borderRadius: 20,
                      marginBottom: 20,
                    }}
                    src={this.state.campaignCoverImage}
                  />
                  <Col span={24}>
                    <Form.Item
                      name="campaignCoverImage"
                      rules={[
                        {
                          required: true,
                          message: "Campaign cover photo is required",
                        },
                      ]}
                      style={{ paddingBottom: 20 }}
                    >
                      <CampaignImageUpload
                        height="289"
                        width="505"
                        onFileUpload={(value) => {
                          this.formRef.current.setFieldsValue({
                            campaignCoverImage: value,
                          });
                          this.setState({ campaignCoverImage: value });
                        }}
                      >
                        <Button>change</Button>
                      </CampaignImageUpload>
                    </Form.Item>
                  </Col>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
        {this.state.openModal && (
          <Modal
            // title="Approved"
            centered
            visible={this.state.openModal}
            footer={null}
            width={610}
            style={{ padding: 35 }}
          >
            <Row style={{ justifyContent: "center" }}>
              <p style={{ fontSize: 22, fontWeight: "bold", marginBottom: 30 }}>
                {this.state.isEdit
                  ? "Upated Successfully"
                  : "Submitted Successfully"}
              </p>
            </Row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p style={{ textAlign: "center" }}>
                Your campaign details are under review with the Avocado team. We
                will get back to you shortly with the next steps.
              </p>

              <Button
                style={{ width: 86, marginTop: 25 }}
                type="primary"
                onClick={() => {
                  this.state.isEdit
                    ? this.navigate("../../../partner/campaigns")
                    : this.navigate("../../partner/campaigns");
                }}
              >
                Done
              </Button>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
