import {
  Col,
  message,
  Pagination,
  Row,
  Skeleton,
  Table,
  Tag,
  Avatar,
  Modal,
  Button,
  Dropdown,
  Menu,
} from 'antd';
import React, { Component } from 'react';
import Design from '../../common/Design';
import Filters from './partials/Filters';
import AvocadoAdmin from '../../api/avocado.admin';
import * as _ from 'lodash';
import { memberFilterToParams, processMemberFilters } from './common';
import { useHistory } from 'react-router';
import { request } from 'https';
import { CloseOutlined, MoreOutlined } from '@ant-design/icons';
import menu from 'antd/lib/menu';
import moment from 'moment';

interface MembersProps {
  match: {
    path: '/admin/members' | '/partner/members';
  };
  history: useHistory;
  location: Location;
}

const DEFAULT_MEMBER_PROFILE_IMAGE = require('../../assets/default-bg.png');

export default class Members extends Component<MembersProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: 'all',
      members: [],
      partners: [],
      selectedMember: {},
      setSubmit: false,
      requests: [],
      filters: null,
      paging: {},
      isMember: false,
      isDeleteConfirm: false,
    };

    this.isAdmin = this.isAdmin.bind(this);

    this.FilterOnSubmit = this.FilterOnSubmit.bind(this);
    this.getMembers = this.getMembers.bind(this);
    this.changePage = this.changePage.bind(this);
    this.getRequests = this.getRequests.bind(this);
  }

  componentDidMount() {
    window.document.title = 'Shorelight - Members';
    const filters = processMemberFilters(
      new URLSearchParams(this.props.location.search),
    );
    console.log(filters.filter);

    this.getMembers(filters.filter ? filters.filter : {});
    this.getRequests();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      const filters = processMemberFilters(
        new URLSearchParams(this.props.location.search),
      );

      this.getMembers(filters.filter ? filters.filter : {});
      this.getRequests();
    }
  }

  isAdmin(): boolean {
    return this.props.match.path === '/admin/members';
  }

  async FilterOnSubmit(values) {
    const filters = { ...values };
    let data = memberFilterToParams(filters);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
    this.setState({ filters: values, selectedValue: values.partner });
  }

  async getMembers(filters = {}) {
    this.setState({ setSubmit: true });

    const user = JSON.parse(sessionStorage.getItem('avocado-user'));

    console.log(filters);
    if (this.isAdmin()) {
      if (!filters['partner']) filters['partner'] = 'all';
    } else {
      filters['partner'] = user.permissions.scope;
    }

    let result;
    try {
      result = await AvocadoAdmin.getMembersById(filters, filters['partner']);
    } catch (e) {
      message.error(e.message, 4);
    }
    const newResult = result?.body?.map((v) => ({ ...v, key: v._id }));

    this.setState({
      members: newResult,
      paging: result.paging,
    });
    this.setState({ setSubmit: false });
    await this.getRequests();
  }

  async getRequests() {
    const user = JSON.parse(sessionStorage.getItem('avocado-user'));
    const partner = JSON.parse(sessionStorage.getItem('avocado-partner'));
    console.log('user', user, 'partner', partner);

    if (!this.isAdmin()) {
      const brandId = user.permissions.scope;
      let result = await AvocadoAdmin.getRequests(brandId);
      console.log(result);

      const newResult = result?.body?.map((v) => ({ ...v, key: v._id }));
      const map = new Map();
      const members = this.state.members;
      const NEWArr = newResult.concat(members);

      // Removing duplicates items
      NEWArr.forEach((item) => {
        if (!map.has(item.key)) {
          map.set(item.key, item);
        }
      });

      this.setState({
        requests: result.body,
        members: Array.from(map.values()),
      });
      console.log(this.state.members);
    }
  }

  //neeed to refactor this code
  async changePage(pageNumber, limit) {
    const filters = { ...this.state.filters, page: pageNumber, limit };
    let data = memberFilterToParams(filters);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
  }

  showMember(member) {
    console.log(member);
    this.setState({ selectedMember: member, isMember: true });
  }

  showConfirm(member) {
    Modal.confirm({
      title: `Are you sure you want to Approve ${
        member.memberId.firstName ? member.memberId.firstName : 'Avocado'
      } ${member.memberId.lastName ? member.memberId.lastName : 'User'}?`,
      okText: 'Approve',
      onOk: () => this.sendRequest(member, 'approved'),
      onCancel() {
        console.log('Cancel');
      },
      okButtonProps: {
        type: 'primary',
        style: {
          float: 'left',
          marginRight: '1rem',
        },
      },
      style: {
        width: '500px',
      },
    });
  }

  showReject(member) {
    Modal.confirm({
      title: `Are you sure you want to Reject ${
        member.memberId.firstName ? member.memberId.firstName : 'Avocado'
      } ${member.memberId.lastName ? member.memberId.lastName : 'User'}?`,
      okText: 'Reject',
      onCancel() {
        console.log('Cancel');
      },
      onOk: () => this.sendRequest(member, 'rejected'),
      okButtonProps: {
        style: {
          float: 'left',
          marginRight: '1rem',

          backgroundColor: 'red',
          textAlign: 'right',
          color: 'white',
          border: 'red',
        },
      },
      style: {
        width: '500px',
      },
    });
  }

  showRemove(member) {
    Modal.confirm({
      title: `Are you sure you want to Remove ${
        member.firstName ? member.firstName : 'Avocado'
      } ${member.lastName ? member.lastName : 'User'}?`,
      okText: 'Remove',
      onCancel() {
        console.log('Cancel');
      },
      onOk: () => this.removeMember(member),
      okButtonProps: {
        style: {
          float: 'left',
          marginRight: '1rem',

          backgroundColor: 'red',
          textAlign: 'right',
          color: 'white',
          border: 'red',
        },
      },
      style: {
        width: '500px',
      },
    });
  }
  showDelete(member) {
    Modal.confirm({
      title: `Are you sure you want to Delete ${
        member.firstName ? member.firstName : 'Avocado'
      } ${member.lastName ? member.lastName : 'User'}?`,
      okText: 'Delete',
      onCancel() {
        console.log('Cancel');
      },
      onOk: () => this.deleteMember(member),
      okButtonProps: {
        style: {
          float: 'left',
          marginRight: '1rem',

          backgroundColor: 'red',
          textAlign: 'right',
          color: 'white',
          border: 'red',
        },
      },
      style: {
        width: '500px',
      },
    });
  }

  async sendRequest(member, status) {
    if (status === 'approved') {
      message.loading(`Adding  Member`);
    }
    const requestObj = {
      status: status,
      memberId: member.memberId._id,
      brandId: member.brandId,
    };
    console.log(requestObj);
    try {
      if (status === 'approved') {
        let result = await AvocadoAdmin.handleMemberRequest(requestObj);
        console.log(result.body);
      } else {
        let result = await AvocadoAdmin.removeMember(requestObj);
        console.log(console.log(result.body));
      }
      message.destroy();
      if (status === 'approved') {
        message.success('Member Added');
      } else message.success('Member Rejected');
      this.getMembers();
      this.getRequests();
    } catch (error) {
      message.error('Error Occured');
    }
  }

  async removeMember(member) {
    message.loading('Removing Member');
    console.log(member);
    const partner = JSON.parse(sessionStorage.getItem('avocado-partner'));

    const requestObj = {
      memberId: member._id,
      brandId: partner._id,
    };
    try {
      let result = await AvocadoAdmin.removeMember(requestObj);
      console.log(result.body);
      message.destroy();
      this.getMembers();
      this.getRequests();
    } catch (error) {
      message.error('Error While Removing');
    }
  }

  async deleteMember(member) {
    this.setState({ isDeleteConfirm: false });
    message.loading('Deleting Member');
    console.log(member);
    const partner = JSON.parse(sessionStorage.getItem('avocado-partner'));

    const requestObj = {
      memberId: member._id,
      brandId: partner._id,
    };
    try {
      let result = await AvocadoAdmin.deletMember(requestObj);
      console.log(result.body);
      message.destroy();
      this.getMembers();
      this.getRequests();
    } catch (error) {
      message.error('Error While Deleting');
    }
  }

  render() {
    const filters = processMemberFilters(
      new URLSearchParams(this.props.location.search),
    );
    console.log(this.state.selectedValue);
    const partner = JSON.parse(sessionStorage.getItem('avocado-partner'));

    const AllColumns = [
      {
        title: () => <div className="justify-start">Member</div>,
        key: 'name',
        render: (record, i) => (
          <div
            onClick={() => this.showMember(record)}
            className="justify-start align-center"
          >
            {record.status === 'pending' ? (
              <Avatar
                src={
                  record.memberId.profilePictureURL
                    ? record.memberId.profilePictureURL
                    : DEFAULT_MEMBER_PROFILE_IMAGE
                }
                size={50}
                shape="circle"
                style={{
                  marginRight: '1rem',
                }}
              />
            ) : (
              <Avatar
                src={
                  record.profilePictureURL
                    ? record.profilePictureURL
                    : DEFAULT_MEMBER_PROFILE_IMAGE
                }
                size={50}
                shape="circle"
                style={{
                  marginRight: '1rem',
                }}
              />
            )}

            <div style={{ fontSize: '1rem' }}>
              {record.status !== 'pending' ? (
                <b>
                  {record.firstName ? record.firstName : 'Avocado'}{' '}
                  {record.lastName ? record.lastName : 'User'}
                </b>
              ) : (
                <b>
                  {record.memberId.firstName
                    ? record.memberId.firstName
                    : 'Avocado'}{' '}
                  {record.memberId.lastName ? record.memberId.lastName : 'User'}
                </b>
              )}
            </div>
          </div>
        ),
      },
      {
        title: () => (
          <div className="justify-center text-center">Total Content</div>
        ),
        dataIndex: ['contents', 'total'],
        key: 'total_content',
      },
      {
        title: () => (
          <div className="justify-center text-center">Approved Content</div>
        ),
        dataIndex: ['contents', 'approved'],
        key: 'approved_content',
      },
      {
        title: () => (
          <div className="justify-center text-center">Total Points</div>
        ),
        dataIndex: 'points',
        key: 'total_points',
      },
      {
        title: () => (
          <div className="justify-center text-center">Avg. Rating</div>
        ),
        dataIndex: ['contents', 'averageRating'],
        key: 'avg_rating',
        render: (data) => (
          <React.Fragment>{data ? data.toFixed(2) : '-'}</React.Fragment>
        ),
      },
      partner && partner.isB2B
        ? {
            title: () => (
              <div className="justify-center text-center">Status</div>
            ),
            key: 'status',
            render: (record, i) => (
              <div className="justify-center text-center">
                {record.membershiprequest &&
                record.membershiprequest.status === 'removed' ? (
                  <Tag
                    key={i}
                    style={{
                      backgroundColor: 'transparent',
                      marginRight: 0,
                      color:
                        record.membershiprequest.status !== 'removed'
                          ? '#EC780E'
                          : 'red',
                      border:
                        record.membershiprequest.status === 'removed'
                          ? '1px solid red'
                          : '1px solid #EC780E',
                    }}
                  >
                    {record.membershiprequest.status === 'removed'
                      ? 'Removed'
                      : 'Active'}
                  </Tag>
                ) : (
                  <Tag
                    key={i}
                    style={{
                      backgroundColor: 'transparent',
                      marginRight: 0,
                      color: record.isActive ? '#EC780E' : '#CECECE',
                      border: !record.isActive
                        ? '1px solid #CECECE'
                        : '1px solid #EC780E',
                    }}
                  >
                    {record.isActive ? 'Active' : 'Inactive'}
                  </Tag>
                )}
              </div>
            ),
          }
        : {
            title: () => (
              <div className="justify-center text-center">Status</div>
            ),
            dataIndex: 'isActive',
            key: 'status',
            render: (isActive, i) => (
              <div className="justify-center text-center">
                <Tag
                  key={i}
                  style={{
                    backgroundColor: 'transparent',
                    marginRight: 0,
                    color: isActive ? '#EC780E' : '#CECECE',
                    border: !isActive
                      ? '1px solid #CECECE'
                      : '1px solid #EC780E',
                  }}
                >
                  {isActive ? 'Active' : 'Inactive'}
                </Tag>
              </div>
            ),
          },
      {
        title: () => (
          <div className="justify-center text-center">University</div>
        ),
        dataIndex: 'university',
        key: 'university',
        render: (university) => (
          <React.Fragment>
            {!this.isAdmin() ? partner.name : university ? university : '-'}
          </React.Fragment>
        ),
      },
      !this.isAdmin() ? (
        {
          title: () => <div className="justify-center text-center"></div>,
          key: 'memberships',
          render: (record, i) => (
            <React.Fragment>
              {record ? (
                <Row justify="end" gutter={[20, 0]}>
                  <React.Fragment>
                    {record.status === 'pending' ? (
                      <Row>
                        <Button
                          onClick={() => this.showConfirm(record)}
                          className="inline-form-item"
                          type="primary"
                        >
                          Approve
                        </Button>

                        <Button
                          style={{ width: '5rem', color: 'red' }}
                          onClick={() => this.showReject(record)}
                          type="text"
                          icon={<CloseOutlined />}
                        >
                          Reject
                        </Button>
                      </Row>
                    ) : (
                      <Row justify="end">
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="0">
                                <a onClick={() => this.showMember(record)}>
                                  Details
                                </a>
                              </Menu.Item>
                              {record.membershiprequest &&
                              record.membershiprequest.status !== 'removed' ? (
                                <Menu.Item key="1">
                                  <a onClick={() => this.showRemove(record)}>
                                    Remove
                                  </a>
                                </Menu.Item>
                              ) : null}

                              {/* <Menu.Item key="2">
                                <a
                                  onClick={() => this.showDelete(record)}
                                  style={{ color: "red" }}
                                >
                                  Delete
                                </a>
                              </Menu.Item> */}
                            </Menu>
                          }
                          trigger={['click']}
                        >
                          <MoreOutlined
                            style={{ fontSize: '1.5rem', color: 'green' }}
                            rotate={90}
                          />
                        </Dropdown>
                        {/* {record.membershiprequest &&
                        record.membershiprequest.status === "removed" ? (
                          <Button
                            style={{
                              backgroundColor: "red",
                              textAlign: "center",
                              color: "white",
                              width: "5rem",
                            }}
                          >
                            Delete
                          </Button>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: "orange",
                              textAlign: "center",
                              color: "white",
                              width: "5rem",
                            }}
                            onClick={() => this.showRemove(record)}
                          >
                            Remove
                          </Button>
                        )} */}
                      </Row>
                    )}
                  </React.Fragment>
                </Row>
              ) : null}
            </React.Fragment>
          ),
        }
      ) : (
        <></>
      ),
    ];
    return (
      <>
        <div className="filter">
          <Filters
            filters={filters}
            FilterOnSubmit={this.FilterOnSubmit}
            isAdmin={this.isAdmin()}
          />
        </div>
        <div className="container">
          <div style={{ marginBottom: '1.6rem' }}>
            <h6>{this.state.paging.totalDocs} Users Found</h6>
          </div>
          <Row>
            <Col span={24}>
              {this.state.setSubmit ? (
                <Skeleton active />
              ) : (
                <>
                  <Table
                    key={1}
                    rowClassName="table-row"
                    dataSource={this.state.members}
                    columns={AllColumns}
                    pagination={false}
                  />
                  <Pagination
                    className="text-center"
                    style={{ padding: '2rem 0rem' }}
                    total={this.state.paging.totalDocs}
                    onChange={(pageNumber, limit) => {
                      this.changePage(pageNumber, limit);
                    }}
                    pageSizeOptions={['10', '15', '30']}
                    pageSize={
                      this.state.paging.limit ? this.state.paging.limit : 10
                    }
                    current={
                      this.state.paging.page ? this.state.paging.page : 1
                    }
                    showSizeChanger
                  />
                </>
              )}
            </Col>
          </Row>
          <Modal
            visible={this.state.isDeleteConfirm}
            onCancel={() => this.setState({ isDeleteConfirm: false })}
            footer={[
              <Button
                type="primary"
                danger
                onClick={() => this.deleteMember(this.state.selectedMember)}
              >
                Yes
              </Button>,
              <Button
                type="default"
                onClick={() => this.setState({ isDeleteConfirm: false })}
              >
                No
              </Button>,
            ]}
          >
            <div style={{ fontWeight: 'bold', fontSize: 22, color: '#1B2014' }}>
              Are you sure you want to remove
              {this.state.selectedMember.firstName
                ? ' ' + this.state.selectedMember.firstName
                : ' Avocado'}{' '}
              {this.state.selectedMember.lastName
                ? this.state.selectedMember.lastName
                : 'User'}
              ?
            </div>
          </Modal>
          <Modal
            visible={this.state.isMember}
            onCancel={() => this.setState({ isMember: false })}
            footer={[
              <Button
                type="primary"
                onClick={() =>
                  this.setState({ isMember: false, isDeleteConfirm: true })
                }
              >
                Remove
              </Button>,
            ]}
          >
            <div className="justify-start align-center">
              <Col>
                <Avatar
                  src={
                    this.state.selectedMember.profilePictureURL
                      ? this.state.selectedMember.profilePictureURL
                      : DEFAULT_MEMBER_PROFILE_IMAGE
                  }
                  size={100}
                  shape="circle"
                  style={{
                    marginRight: '1rem',
                  }}
                />
              </Col>
              <Col>
                <Row>
                  <Col span={24}>
                    {this.state.selectedMember.status &&
                    this.state.selectedMember.status === 'pending' ? (
                      <p style={{ fontSize: '1rem', fontWeight: 600 }}>
                        {this.state.selectedMember.memberId.firstName
                          ? this.state.selectedMember.memberId.firstName
                          : 'Avocado'}{' '}
                        {this.state.selectedMember.memberId.lastName
                          ? this.state.selectedMember.memberId.lastName
                          : 'User'}
                      </p>
                    ) : (
                      <p style={{ fontSize: '1rem', fontWeight: 600 }}>
                        {this.state.selectedMember.firstName
                          ? this.state.selectedMember.firstName
                          : 'Avocado'}{' '}
                        {this.state.selectedMember.lastName
                          ? this.state.selectedMember.lastName
                          : 'User'}
                      </p>
                    )}
                  </Col>
                </Row>
                {this.isAdmin() || partner.isB2B ? (
                  <Row>
                    {this.state.selectedMember.status &&
                    this.state.selectedMember.status === 'pending' ? (
                      <p>
                        {this.state.selectedMember.memberId.email
                          ? this.state.selectedMember.memberId.email
                          : '-'}
                      </p>
                    ) : (
                      <p>
                        {this.state.selectedMember.email
                          ? this.state.selectedMember.email
                          : '-'}
                      </p>
                    )}
                  </Row>
                ) : null}
                {!this.isAdmin() && partner.isB2B ? (
                  <Row style={{ marginTop: '1rem' }}>
                    <p>
                      Joined On :{' '}
                      {moment(this.state.selectedMember.lastUpdateAt).format(
                        'DD/MM/YYYY',
                      )}
                    </p>
                  </Row>
                ) : null}
              </Col>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
