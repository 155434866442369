import { SearchOutlined } from "@ant-design/icons";
import { Select, Row, Col, Input, Button, Form, DatePicker, Tag } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import moment from "moment";
import { PartnerContext } from "../../../context/partnerContext";

const Filters = ({ onSubmit, isAdmin, selectedPartner, filters }) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [partners, setPartners] = useContext(PartnerContext);
  const filterValues = {
    query: filters.filter && filters.filter.query ? filters.filter.query : "",
    partner:
      filters.filter && filters.filter.partner ? filters.filter.partner : "all",
    startDate:
      filters.filter && filters.filter.startDate
        ? filters.filter.startDate
        : null,
    endDate:
      filters.filter && filters.filter.endDate ? filters.filter.endDate : null,
  };

  return (
    <div className="subheader">
      <Formik
        initialValues={filterValues}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values);
          actions.setSubmitting(true);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmitCapture={handleSubmit} colon={false}>
            <Row className="container">
              <Col span={6}>
                <h3>Leaderboard</h3>
                {/* {isAdmin && selectedPartner === "all" ? (
                  <div style={{ paddingTop: "0.6rem" }}>
                    {[
                      { code: "all", name: "Global" },
                      { code: "LKA", name: "Sri Lanka" },
                    ].map((country, i) => (
                      <Tag.CheckableTag
                        key={i}
                        checked={i === 0}
                        onChange={(checked) => {
                          console.log(checked);
                        }}
                      >
                        {country.name}
                      </Tag.CheckableTag>
                    ))}
                  </div>
                ) : null} */}
              </Col>
              {/* <Col span={18}> */}
              {/* <Row justify="center"> */}
              <Col span={18}>
                <Row style={{ float: "right" }}>
                  <Col style={{ marginLeft: 10 }}>
                    <Form.Item className="inline-form-item">
                      <Input
                        style={{ width: "250px" }}
                        value={values.query}
                        placeholder="Search"
                        name="query"
                        className="rounded"
                        prefix={<SearchOutlined />}
                        onChange={handleChange}
                      />{" "}
                    </Form.Item>
                  </Col>

                  {isAdmin ? (
                    <Col style={{ marginLeft: 10 }}>
                      <Form.Item className="inline-form-item" label="Partners">
                        <Select
                          dropdownClassName="rounded"
                          defaultValue="all"
                          className="rounded select"
                          value={values.partner}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              // @ts-ignore
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          optionFilterProp="children"
                          onChange={(e) => {
                            setFieldValue("partner", e);
                          }}
                        >
                          <Option value="all">All</Option>
                          {partners.map((partner, i) => (
                            <Option value={partner._id}>{partner.name}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : null}
                  <Col style={{ marginLeft: 10 }}>
                    <Form.Item label="Date" className="inline-form-item">
                      <RangePicker
                        dropdownClassName="rounded"
                        className="rounded"
                        value={
                          values.startDate && values.endDate
                            ? [moment(values.startDate), moment(values.endDate)]
                            : [null, null]
                        }
                        ranges={{
                          Today: [moment(), moment()],
                          Yesterday: [
                            moment().subtract(1, "day"),
                            moment().subtract(1, "day"),
                          ],
                          "This Week": [
                            moment().startOf("week"),
                            moment().endOf("week"),
                          ],
                          "Last Week": [
                            moment().subtract(1, "week").startOf("week"),
                            moment().subtract(1, "week").endOf("week"),
                          ],
                          "This Month": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                          ],
                          "Last Month": [
                            moment().subtract(1, "month").startOf("month"),
                            moment().subtract(1, "month").endOf("month"),
                          ],
                        }}
                        onChange={(e) => {
                          console.log(e);
                          if (e) {
                            let startDate = moment(e[0]).format("YYYY/MM/DD");
                            let endDate = moment(e[1]).format("YYYY/MM/DD");
                            console.log(startDate, endDate);
                            setFieldValue("startDate", startDate);
                            setFieldValue("endDate", endDate);
                          } else {
                            setFieldValue("startDate", null);
                            setFieldValue("endDate", null);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col style={{ marginLeft: 10 }}>
                    <Button htmlType="submit" type="default">
                      Apply
                    </Button>
                  </Col>
                </Row>
              </Col>
              {/* </Row> */}
              {/* </Col> */}
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Filters;
