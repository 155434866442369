import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import PartnerProfile from "./Profile/profile";

import Dashboard from "../Dashboard";
import Members from "../Members";
import Content from "../Content";
import Leaderboard from "../Leaderboard";
import Campaign from "../Compaign";
import NewCampaign from "../Compaign/newCampaign";
import ViewCampaign from "../Compaign/viewCampaign";
import Bills from "../Bills";
import PrintInvoice from "../Bills/printInvoice";
const PartnerRoutes = (props) => {
  const { match } = props;
  console.log(props);
  return (
    <Switch>
      <Route exact path="/partner" component={Dashboard}></Route>
      <Route exact path={`${match.url}/members`} component={Members}></Route>
      <Route exact path={`${match.url}/content`} component={Content}></Route>
      <Route
        exact
        path={`${match.url}/leaderboard`}
        component={Leaderboard}
      ></Route>
      <Route exact path={`${match.url}/campaigns`} component={Campaign}></Route>
      <Route
        exact
        path={`${match.url}/campaigns/create`}
        component={NewCampaign}
      ></Route>
      <Route
        exact
        path={`${match.url}/campaigns/edit/:id`}
        component={NewCampaign}
      ></Route>
      <Route
        exact
        path={`${match.url}/campaigns/view/:id`}
        component={ViewCampaign}
      ></Route>
      <Route
        exact
        path={`${match.url}/profile`}
        component={PartnerProfile}
      ></Route>
      {/* <Route exact path={`${match.url}/billing`} component={Bills}></Route> */}
      <Route
        exact
        path={`${match.url}/invoice/:no`}
        component={PrintInvoice}
      ></Route>
    </Switch>
  );
};

export default withRouter(PartnerRoutes);
