import React, { Component } from "react";
import { Col, Pagination, Row, Skeleton, Table, Tag, Avatar } from "antd";

import Filters from "./partials/Filters";

// Data
import AvocadoAdmin from "../../api/avocado.admin";
import { leaderboardFilterToParams, processLeaderboardFilters } from "./common";

const DEFAULT_MEMBER_PROFILE_IMAGE = require("../../assets/default-bg.png");

export default class PartnerLeaderboard extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      paging: {
        totalDocs: 0,
      },
      leaderboard: [],
      partner: "all",
      filters: {},
    };

    this.isAdmin = this.isAdmin.bind(this);

    this.getLeaderboard = this.getLeaderboard.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  componentDidMount() {
    window.document.title = "Shorelight - Leaderboard";
    const filters = processLeaderboardFilters(
      new URLSearchParams(this.props.location.search)
    );

    this.getLeaderboard(filters.filter ? filters.filter : {});
    // this.getLeaderboard();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      const filters = processLeaderboardFilters(
        new URLSearchParams(this.props.location.search)
      );

      this.getLeaderboard(filters.filter ? filters.filter : {});
    }
  }

  isAdmin(): boolean {
    return this.props.match.path === "/admin/leaderboard";
  }

  async onSubmit(filter) {
    this.setState({
      partner: filter.partner,
      filters: filter,
    });
    let data = leaderboardFilterToParams(filter);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
  }

  async getLeaderboard(filters: any = {}) {
    this.setState({ isLoading: true });

    const user = JSON.parse(sessionStorage.getItem("avocado-user"));

    let params = { ...filters };

    if (this.isAdmin()) {
      if (filters["partner"]) params["brandId"] = filters["partner"];
    } else {
      params["brandId"] = user.permissions.scope;
    }

    if (filters.partner === "all" && this.isAdmin()) {
      delete params.brandId;
    }

    try {
      let result = await AvocadoAdmin.getLeaderboard(params);
      this.setState({ leaderboard: result.body, paging: result.paging });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async changePage(pageNumber, limit) {
    const filters = { ...this.state.filters, page: pageNumber, limit };
    let data = leaderboardFilterToParams(filters);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
    // this.getLeaderboard(filters);
  }

  render() {
    const filters = processLeaderboardFilters(
      new URLSearchParams(this.props.location.search)
    );
    const AllColumns = [
      {
        title: () => <div className="justify-start">Rank</div>,
        dataIndex: "rank",
        key: "rank",
      },
      {
        title: () => <div className="justify-start">Member</div>,
        key: "name",
        render: (record, i) => (
          <div className="justify-start align-center">
            <Avatar
              src={
                record.member && record.member.profilePictureURL
                  ? record.member.profilePictureURL
                  : DEFAULT_MEMBER_PROFILE_IMAGE
              }
              size={50}
              shape="circle"
              style={{
                marginRight: "1rem",
              }}
            />

            <div style={{ fontSize: "1rem" }}>
              <b>
                {record.member && record.member.firstName
                  ? record.member.firstName
                  : "Avocado"}{" "}
                {record.member && record.member.lastName
                  ? record.member.lastName
                  : "User"}
              </b>
            </div>
          </div>
        ),
      },
      {
        title: () => (
          <div className="justify-center text-center">Total Content</div>
        ),
        dataIndex: ["contents", "total"],
        key: "total_content",
      },
      {
        title: () => (
          <div className="justify-center text-center">Approved Content</div>
        ),
        dataIndex: ["contents", "approved"],
        key: "approved_content",
      },
      {
        title: () => (
          <div className="justify-center text-center">Total Points</div>
        ),
        dataIndex: "totalPoints",
        key: "total_points",
      },
      {
        title: () => (
          <div className="justify-center text-center">Avg. Rating</div>
        ),
        dataIndex: ["contents", "averageRating"],
        key: "avg_rating",
        render: (data) => (
          <React.Fragment>
            {(Math.round(data * 100) / 100).toFixed(2)}
          </React.Fragment>
        ),
      },
      {
        title: () => <div className="justify-center text-center">Status</div>,
        dataIndex: "status",
        key: "status",
        render: () => (
          <Tag
            className="rounded"
            style={{
              background: "transparent",
              marginRight: 0,
              color: "#EC780E",
              border: "1px solid #EC780E",
            }}
          >
            Active
          </Tag>
        ),
      },
    ];
    return (
      <React.Fragment>
        <div className="filter">
          <Filters
            filters={filters}
            onSubmit={this.onSubmit}
            isAdmin={this.isAdmin()}
            selectedPartner={this.state.partner}
          />
        </div>
        <div className="container">
          <div style={{ marginBottom: "1.6rem" }}>
            <h6>{this.state.paging ? this.state.paging.totalDocs : 0} Ranks</h6>
          </div>
        </div>
        <Row className="container">
          <Col span={24}>
            {this.state.isLoading ? (
              <Skeleton active></Skeleton>
            ) : (
              <React.Fragment>
                <Table
                  rowClassName="table-row"
                  pagination={false}
                  dataSource={this.state.leaderboard}
                  columns={AllColumns}
                />
                <Pagination
                  className="text-center"
                  style={{ padding: "2rem 0rem" }}
                  total={this.state.paging.totalDocs}
                  onChange={(pageNumber, limit) => {
                    this.changePage(pageNumber, limit);
                  }}
                  pageSizeOptions={["10", "15", "30"]}
                  pageSize={
                    this.state.paging.limit ? this.state.paging.limit : 10
                  }
                  current={this.state.paging.page ? this.state.paging.page : 1}
                  showSizeChanger
                ></Pagination>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
