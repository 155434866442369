import axios from "axios";

const baseURL = () => {
  const apiUrl = `${process.env.REACT_APP_API_MODERATOR}/`;
  return apiUrl;
};

const getInstance = () => {
  const token = sessionStorage.getItem('avocado-token');
  return axios.create({
    headers: { Authorization: `Bearer ${token}` },
  });
};

const handleResponse = (response) => {
  return {
    body: response && response.data ? response.data.body : null,
    errors: response && response.data ? response.data.errors : {},
    status: response && response.status ? response.status : 500,
    message: response && response.data ? response.data.message : '',
    paging: response && response.data ? response.data.paging : null,
  };
};

const handleError = (errorObject) => {
  console.log(errorObject);
  const data = errorObject.response
    ? errorObject.response.data
    : { data: { status: 500, message: errorObject.message } };
  throw new Error(data.message);
};

const AvocadoModerator = {
  getUserByToken: () => {
    let url = process.env.REACT_APP_AUTH_ME_URL;
    console.log('shr REACT_APP_AUTH_ME_URL', process.env.REACT_APP_AUTH_ME_URL);

    return getInstance()
      .post(url)
      .then(handleResponse)
      .catch((error) => {
        console.log({ error });
        console.log('shr force reloading page');
        window.location.reload();
      });
  },
  signIn: (user) => {
    let url = `${baseURL()}auth/sign-in`;
    return axios.post(url, user).then(handleResponse).catch(handleError);
  },
  getContentBucket: () => {
    let url = `${baseURL()}api/content/bucket`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  approveContent: (payload) => {
    let url = `${baseURL()}api/content/rate`;
    return getInstance()
      .post(url, payload)
      .then(handleResponse)
      .catch(handleError);
  },
  getAllNotification: () => {
    let url = `${baseURL()}api/notifications`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  markAllAsRead: () => {
    let url = `${baseURL()}api/notifications/all`;
    return getInstance().delete(url).then(handleResponse).catch(handleError);
  },
  deleteNotification: (id) => {
    let url = `${baseURL()}api/notifications/${id}`;
    return getInstance().delete(url).then(handleResponse).catch(handleError);
  },
};

export default AvocadoModerator;
