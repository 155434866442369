import { Row, Col, Skeleton, message, Table } from "antd";
import React, { Component } from "react";
import Design from "../../../common/Design";
import AdminModeratorFilter from "./Partials/admin-moderators-filter";
import AdminModeratorTotalContent from "./Partials/admin-moderator-total-content";
import AdminModeratorRatings from "./Partials/admin-moderator-ratings";
import AdminModeratorSession from "./Partials/admin-moderator-session";
import AddModerator from "./Partials/add-moderator";
import AvocadoAdmin from "../../../api/avocado.admin";
import EditModerator from "./Partials/edit-moderator";
import Lottie from "react-lottie";

import nothingFound from "../../../assets/nothingFound.json";

const DEFAULT_MEMBER_PROFILE_IMAGE = require("../../../assets/default-bg.png");
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: nothingFound,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default class AdminModerators extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      isAdd: false,
      isLoading: false,
      moderators: [],
    };

    this.setAddModal = this.setAddModal.bind(this);
    this.setEditModal = this.setEditModal.bind(this);
    this.getAllModerators = this.getAllModerators.bind(this);
    this.onAddModerator = this.onAddModerator.bind(this);
    this.onDeleteModerator = this.onDeleteModerator.bind(this);
  }

  componentDidMount() {
    window.document.title = "Shorelight - Moderators";

    this.getAllModerators();
  }

  setAddModal(value) {
    this.setState({ isAdd: value });
  }
  setEditModal(value) {
    console.log(value);
    this.setState({ isEdit: value });
  }

  async getAllModerators() {
    this.setState({ isLoading: true });
    try {
      let result = await AvocadoAdmin.getAllModerator();
      this.setState({ moderators: result.body });
    } catch (error) {
      console.log(error);
    }
    this.setState({ isLoading: false });
  }

  async onAddModerator(values) {
    message.loading("Adding Moderator");
    this.setAddModal(false);
    try {
      let result = await AvocadoAdmin.createModerator(values);
      this.getAllModerators();
    } catch (error) {}
    message.destroy();
    message.error("Something went wrong");
  }

  async onDeleteModerator(id) {
    this.setEditModal(false);
    try {
      let result = await AvocadoAdmin.deleteModerator(id);
      this.getAllModerators();
    } catch (error) {
      console.log(error);
    }
  }

  async onSubmit(filter) {
    console.log(filter);
  }

  render() {
    const AllColumns = [
      {
        title: "Moderator",
        key: "name",
        render: (record, i) => (
          <React.Fragment>
            <Row key={i}>
              <Col>
                <img
                  height={50}
                  width={50}
                  className="rounded"
                  style={{ marginRight: "1rem" }}
                  src={
                    record.profilePicture
                      ? record.profilePicture
                      : DEFAULT_MEMBER_PROFILE_IMAGE
                  }
                />
              </Col>
              <Col style={{ alignItems: "center" }}>
                <div style={{ fontSize: "1rem" }}>
                  <b>
                    {record.firstName ? record.firstName : "Avocado"}{" "}
                    {record.lastName ? record.lastName : "Moderator"}
                  </b>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
      },
    ];
    return (
      <React.Fragment>
        <Row className="filter">
          <Col span={24}>
            <AdminModeratorFilter
              setAddModal={this.setAddModal}
              setEditModal={this.setEditModal}
              onAddModerator={this.onAddModerator}
              onSubmit={this.onSubmit}
              moderators={this.state.moderators}
            ></AdminModeratorFilter>
          </Col>
        </Row>
        {this.state.isLoading === false ? (
          <React.Fragment>
            <div className="container">
              {this.state.moderators.length > 0 ? (
                <React.Fragment>
                  <Row style={{ paddingBottom: "1rem" }}>
                    <Col span={6}>
                      <p className="count">
                        {this.state.moderators.length} Moderators{" "}
                      </p>
                    </Col>
                  </Row>
                  {/* <Row gutter={[20, 20]} style={{ paddingTop: "1rem" }}>
                    <Col span={4}>
                      {this.state.moderators.map((moderator, i) => (
                        <Row
                          justify="center"
                          key={i}
                          className={`moderator rounded ${
                            i == 0 ? "card" : ""
                          }`}
                        >
                          <p>
                            {moderator.firstName} {moderator.lastName}
                          </p>
                        </Row>
                      ))}
                    </Col>
                    <Col span={20}>
                      <Row gutter={[20, 20]}>
                        <Col span={14}>
                          <AdminModeratorTotalContent></AdminModeratorTotalContent>
                        </Col>
                        <Col span={10}>
                          <AdminModeratorRatings></AdminModeratorRatings>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <AdminModeratorSession></AdminModeratorSession>
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}
                  <Table
                    className="moderator-table"
                    pagination={false}
                    dataSource={this.state.moderators}
                    columns={AllColumns}
                  />
                </React.Fragment>
              ) : (
                <Lottie options={defaultOptions} height={400} width={400} />
              )}
            </div>
          </React.Fragment>
        ) : (
          <Skeleton className="container" active></Skeleton>
        )}

        <AddModerator
          setAddModal={this.setAddModal}
          isAdd={this.state.isAdd}
          onSubmit={this.onAddModerator}
        ></AddModerator>
        <EditModerator
          setEditModal={this.setEditModal}
          moderators={this.state.moderators}
          isEdit={this.state.isEdit}
          onSubmit={this.onDeleteModerator}
        ></EditModerator>
      </React.Fragment>
    );
  }
}
