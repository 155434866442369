import {
  StarFilled,
  VideoCameraFilled,
  VideoCameraTwoTone,
} from "@ant-design/icons";
import { Checkbox, Col, Row } from "antd";
import React from "react";
import * as _ from "lodash";

const VideoCard = ({ video, onSelect, onChecked, selectedImages }) => {
  return (
    <div
      className="card"
      style={{
        overflow: "hidden",
        borderRadius: 10,
        maxWidth: "295px",
      }}
    >
      <Row
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          onSelect();
        }}
      >
        <Col className="content-wrap">
          <video
            src={video.contentURL}
            style={{
              width: "100%",
              height: "inherit",
              maxHeight: 150,
              objectFit: "cover",
              zIndex: 1,
            }}
          />

          <div className="overlay-video">
            {" "}
            <VideoCameraFilled style={{ color: "#EC780E" }} />
          </div>
          <div className="overlay">
            {" "}
            <StarFilled style={{ color: "#ffd700" }}></StarFilled>
            {video.rating}
          </div>
        </Col>
      </Row>
      <Row className="footer">
        <Col span={2}>
          <Checkbox
            onChange={() => onChecked(video)}
            checked={_.includes(selectedImages, video)}
            className="rounded"
          ></Checkbox>
        </Col>
        <Col span={22}>
          {" "}
          <p
            style={{
              padding: "0rem",
            }}
            className="image-caption"
          >
            {video.caption ? video.caption : ""}
          </p>
        </Col>
        {/* <Col>
          <StarFilled style={{ color: "#ffd700" }} />
          <span style={{ padding: "5px" }}>{image.rating}</span>
        </Col> */}
      </Row>
    </div>
  );
};
export default VideoCard;
