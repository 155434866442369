import React, { Component } from 'react';
import AdminTierLimit from './Partials/admin-tier-limit';
import { Row, Col, Skeleton } from 'antd';
// import bobross from "../assets/bobross.png";
import logo from '../../../assets/bobross.png';
import AdminPointsFilter from './Partials/admin-points-filter';

import AdminPointsStars from './Partials/admin-points-stars';
import AvocadoAdmin from '../../../api/avocado.admin';

interface AdminPointsState {
  editable: boolean;
  stars: [];
  tiers: [];
  decayPointEnable: boolean;
}
export default class AdminPoints extends Component<any, AdminPointsState> {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      stars: [],
      tiers: [],
      decayPointEnable: false,
    };

    this.onEdit = this.onEdit.bind(this);
    this.getPoints = this.getPoints.bind(this);
    this.getDeacayPoints = this.getDeacayPoints.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    window.document.title = 'Shorelight - Points';

    this.getPoints();
    this.getDeacayPoints();
  }

  async getDeacayPoints() {
    try {
      let result = await AvocadoAdmin.getDecayStatus();
      this.setState({
        decayPointEnable: result.body.decayEnabled,
      });
    } catch (error) {}
  }

  async onChange() {
    this.setState({
      decayPointEnable: !this.state.decayPointEnable,
    });
    let enable = !this.state.decayPointEnable;
    try {
      let result = await AvocadoAdmin.updateDecayStatus({
        decayEnabled: enable,
      });

      this.setState({
        decayPointEnable: result.body.decayEnabled,
      });
    } catch (error) {
      this.setState({
        decayPointEnable: !this.state.decayPointEnable,
      });
    }
  }
  onEdit(value) {
    this.setState({ editable: value });
  }

  async getPoints() {
    try {
      let result = await AvocadoAdmin.getPoints();
      this.setState({
        stars: result.body.stars,
        tiers: result.body.tiers,
      });
    } catch (error) {}
  }

  render() {
    const { state } = this;
    return (
      <React.Fragment>
        <Row className="filter">
          <Col span={24}>
            <AdminPointsFilter
              decayPointEnable={this.state.decayPointEnable}
              editable={this.state.editable}
              onEdit={this.onEdit}
              onChange={this.onChange}
            ></AdminPointsFilter>
          </Col>
        </Row>
        <div className="container">
          {state.stars.length > 0 ? (
            <Row gutter={[20, 20]}>
              <Col span={16}>
                <Row>
                  <span className="page-sub-header">Tier Limits</span>
                </Row>
                <Row>
                  <Col span={24}>
                    <AdminTierLimit
                      editable={this.state.editable}
                      tiers={this.state.tiers}
                    ></AdminTierLimit>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <span className="page-sub-header">Points Per Star</span>
                </Row>
                <Row>
                  <Col span={24}>
                    <AdminPointsStars
                      editable={this.state.editable}
                      stars={this.state.stars}
                    ></AdminPointsStars>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Skeleton active></Skeleton>
          )}
        </div>
      </React.Fragment>
    );
  }
}
