import React, { Component, useContext, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

import './header.scss';

import logo from '../assets/logo.svg';
import logo2 from '../assets/logo2.svg';

import Icon, { DownOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';
import { Row, Col, Dropdown, Menu, Button, Modal, Popover, Badge } from 'antd';
import { UserContext } from '../context/userContext';
import propic from '../assets/proPic.jpeg';
import notification from '../assets/notification.png';
import clock from '../assets/clock.svg';
import trash from '../assets/trash.svg';
import AvocadoAdmin from '../api/avocado.admin';

import { campaign } from '../pages/Compaign/dump';
import { useHistory } from 'react-router-dom';

declare const window: any;

const Header = ({ menuItems, ...props }) => {
  let partner;

  const history = useHistory();
  const profile = JSON.parse(sessionStorage.getItem('avocado-user'));

  try {
    partner = JSON.parse(sessionStorage.getItem('avocado-partner'));
  } catch (e) {
    console.log(e);
  }

  const [userState, setUser] = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [notifocations, setNotification] = useState([]);

  const isAdmin = () => {
    return history.location.pathname.includes('admin');
  };

  const menu = (
    <Menu
      style={{
        overflow: 'hidden',
      }}
    >
      {userState ? (
        <React.Fragment>
          {' '}
          {profile.permissions.role === 'brand' ? (
            <Menu.Item>
              <Link to='/partner/profile'>Profile</Link>
            </Menu.Item>
          ) : null}
          <Menu.Item onClick={() => signOut()}>
            <Link to='/'>Log Out</Link>
          </Menu.Item>
        </React.Fragment>
      ) : null}
    </Menu>
  );

  useEffect(() => {
    console.log('innn');

    getNotification();
  }, []);

  const markAllAsRead = async () => {
    try {
      let result = await AvocadoAdmin.markAllAsRead();
      getNotification();
    } catch (error) { }
  };
  const clearNotification = async (id) => {
    try {
      let result = await AvocadoAdmin.deleteNotification(id);
      getNotification();
    } catch (error) { }
  };
  // const notifications = [
  //   {
  //     title: '',
  //     partner: 'Partner',
  //     name: 'name',
  //     number: 'number',
  //     time: '2 hours ago',
  //     type: 'campaign',
  //   },
  //   {
  //     title: '',
  //     name: 'James',
  //     count: 5,
  //     time: '2 hours ago',
  //     type: 'rating',
  //   },
  //   {
  //     title: 'New member request from Terry',
  //     name: 'James',
  //     requestBy: 'Lyda VonRueden',
  //     time: '2 hours ago',
  //     type: 'request',
  //   },
  //   {
  //     title: 'New member request from Terry, Gaylord Inc.',
  //     time: '2 hours ago',
  //     partner: 'Partner',
  //     name: 'name',
  //     number: 'number',
  //     type: 'campaign',
  //   },
  // ];
  const content = (
    <div className='popover'>
      <div className='popover-header'>
        <span className='popover-title'>
          Notifications{' '}
          <Badge
            style={{ background: '#FDF1E5', color: '#EB7304' }}
            count={notifocations.length}
          />
        </span>

        <button
          className='popover-read-all'
          onClick={() => markAllAsRead()}
          style={{
            background: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Mark as read
        </button>
      </div>
      {notifocations.map((item) => {
        return (
          <div className='popover-item'>
            {item.userType == 'partner' ? (
              <div className='full-width'>
                <span className='popover-item-title'>{item.data.subject}</span>
                <p className='popover-item-description'>
                  {item.name == 'CONTENT_MODERATED' && (
                    <span>
                      Hey there,{' '}
                      <span className='special'>{item.data.name} </span> just
                      uploaded a{' '}
                      <span className='special'>{item.data.rating}</span> star
                      rated image!
                    </span>
                  )}
                  {item.name == 'CAMPAIGN_ACTIVE' && (
                    <span>
                      Hey there, Your{' '}
                      <span className='special'>{item.data.campaignTitle}</span>{' '}
                      campaign is active! Click{' '}
                      <a
                        className='special'
                        href={item.data.link}
                        target='_blank'
                      >
                        here
                      </a>{' '}
                      to be directed to your dashboard to view more details.
                    </span>
                  )}
                  {item.name == 'CAMPAIGN_COMPLETED' && (
                    <span>
                      Hey there, Your{' '}
                      <span className='special'>{item.data.campaignTitle}</span>{' '}
                      campaign has just wrapped up.{' '}
                      <span className='special'>{item.data.totalContent} </span>{' '}
                      pieces of content were generated with{' '}
                      <span className='special'>
                        {item.data.total3PlusStars}{' '}
                      </span>
                      of them being 3 stars or higher from{' '}
                      <span className='special'>{item.data.totalUsers}</span>{' '}
                      active users. Click{' '}
                      <a
                        className='special'
                        href={item.data.link}
                        target='_blank'
                      >
                        here
                      </a>{' '}
                      to be directed to your dashboard to view campaign stats
                      and check out the cool content your memberes generated!
                    </span>
                  )}
                  {item.name == 'CAMPAIGN_APPROVED' && (
                    <span>
                      Hey there, Your{' '}
                      <span className='special'>
                        {item.data.campaignTitle}{' '}
                      </span>
                      campaign has been approved. It will go live on{' '}
                      <span className='special'>{item.data.startDate}</span>.
                      Click{' '}
                      <a
                        className='special'
                        href={item.data.link}
                        target='_blank'
                      >
                        here
                      </a>{' '}
                      to be directed to your dashboard to view more details.
                    </span>
                  )}
                  {item.name == 'CAMPAIGN_REJECTED' && (
                    <span>
                      Hey there, Your{' '}
                      <span className='special'>{item.data.campaignTitle}</span>{' '}
                      campaign has been rejected. You can edit it and resubmit
                      by logging in to the dashboard{' '}
                      <a
                        className='special'
                        href={item.data.link}
                        target='_blank'
                      >
                        here
                      </a>{' '}
                    </span>
                  )}
                  {item.name == 'MEMBERSHIP_REQUEST_PENDING' && (
                    <span>
                      Hey there, You have a new join in request from{' '}
                      <span className='special'>{item.data.name}</span>.
                    </span>
                  )}
                </p>
                <div className='popover-footer'>
                  <span>
                    <img src={clock} /> {' ' + item.time}
                  </span>
                  <span>
                    <img
                      src={trash}
                      onClick={() => {
                        clearNotification(item.id);
                      }}
                    />{' '}
                    clear
                  </span>
                </div>
              </div>
            ) : (
              <div className='full-width'>
                <span className='popover-item-title'>{item.data.subject}</span>
                <p className='popover-item-description'>
                  {item.name == 'CONTENT_MODERATED' && (
                    <span>
                      Hey there, There's a new 5 star image waiting for you in
                      the content library!
                    </span>
                  )}
                </p>
                <div className='popover-footer'>
                  <span>
                    <img src={clock} /> {' ' + item.time}
                  </span>
                  <span>
                    <img
                      src={trash}
                      onClick={() => {
                        clearNotification(item.id);
                      }}
                    />{' '}
                    clear
                  </span>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
  const getNotification = async () => {
    try {
      let result = await AvocadoAdmin.getAllNotification();
      console.log('result', result);

      setNotification(result.body);
    } catch (error) { }
  };

  const signOut = () => {
    sessionStorage.clear();
    window.Intercom('shutdown');
    setUser(null);
  };

  const onResetPassword = () => {
    setOpenModal(false);
    setOpenResetModal(true);
  };
  // console.clear();
  //remove this when going live
  // console.log(
  //   "%c You are not supposed to be here",
  //   "font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)"
  // );
  let name = partner
    ? partner.name
    : `${profile.firstName} ${profile.lastName}`;
  let profilePicture = partner
    ? partner.profilePicture
    : profile.profilePictureURL;
  let email = partner ? partner.email : profile.email;

  return (
    <div className='header-area'>
      <header className='header'>
        <div className='header-container'>
          <Row align='middle' className='nav'>
            <Col span={3}>
              <Row>
                <Link
                  to={
                    profile.permissions.role === 'wladmin'
                      ? '/admin'
                      : '/partner'
                  }
                  style={{ fontSize: 24, fontWeight: 600 }}
                >
                  Shorelight
                  {/* <img
                    src={logo}
                    alt="Avocado"
                    style={{
                      height: "1.7em",
                    }}
                  /> */}
                </Link>
              </Row>
            </Col>
            <Col span={16}>
              <Row justify='center'>
                <ul className='menu-items'>
                  {menuItems.map((item, i) => (
                    <li key={i}>
                      <NavLink
                        exact
                        activeClassName='is-active'
                        to={item.route}
                      >
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                  <li style={{ float: 'right' }}></li>
                </ul>
              </Row>
            </Col>

            <Col
              span={4}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <Popover placement='bottom' content={content}>
                <a>
                  <img src={notification} style={{ width: 20 }} />
                </a>
              </Popover> */}

              <div className='justify-end align-center'>
                <span
                  style={{
                    color: 'white',
                    fontSize: '0.9rem',
                    fontWeight: 600,
                    marginRight: '0.6rem',
                    marginLeft: 30,
                  }}
                >
                  {name}
                </span>

                <a onClick={() => setOpenModal(true)}>
                  <Avatar src={profilePicture} style={{ background: 'white' }}>
                    <img src={propic} style={{
                      width: "100%",
                      transform: "scale(2)"
                    }} />
                  </Avatar>
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </header>

      <Modal
        visible={openModal}
        // title="Title"
        // onOk={}
        onCancel={() => setOpenModal(false)}
        footer={[
          <Button key='back' onClick={() => onResetPassword()}>
            Reset Password
          </Button>,
          <Button key='submit' type='primary' onClick={() => signOut()}>
            Logout
          </Button>,
        ]}
      >
        <Row>
          <Col lg={6}>
            <img src={profilePicture ? profilePicture : logo2} />
          </Col>
          <Col
            lg={18}
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p style={{ fontSize: 22, fontWeight: 'bold', marginBottom: 0 }}>
              {name}
            </p>
            <p>{email}</p>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={openResetModal}
        // title="Title"
        // onOk={}
        onCancel={() => setOpenResetModal(false)}
        footer={[
          <Button
            key='submit'
            type='default'
            onClick={() => setOpenResetModal(false)}
          >
            close
          </Button>,
        ]}
      >
        <p style={{ fontSize: 22, fontWeight: 'bold', marginBottom: 0 }}>
          A password reset link has been sent
          <br /> to your email address
        </p>
      </Modal>
    </div>
  );
};

export default Header;
