import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { Select, Row, Col, Input, Button, Form, DatePicker, Tag } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import moment from "moment";
import { PartnerContext } from "../../../context/partnerContext";
import { useHistory } from "react-router-dom";

const Filters = ({ onSubmit, isAdmin, selectedPartner, filters, rank }) => {
  const history = useHistory();

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [partners, setPartners] = useContext(PartnerContext);
  const filterValues = {
    query: filters.filter && filters.filter.query ? filters.filter.query : null,
    partner:
      filters.filter && filters.filter.partner ? filters.filter.partner : null,
    startDate:
      filters.filter && filters.filter.startDate
        ? filters.filter.startDate
        : null,
    endDate:
      filters.filter && filters.filter.endDate ? filters.filter.endDate : null,
    status:
      filters.filter && filters.filter.status ? filters.filter.status : null,
  };

  const navigate = (path) => {
    history.push(path);
  };
  return (
    <div className="subheader">
      <Formik
        initialValues={filterValues}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values);
          actions.setSubmitting(true);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmitCapture={handleSubmit} colon={false}>
            <Row className="container">
              <Col span={4}>
                <h3>Campaigns</h3>
              </Col>
              <Col span={20}>
                <Row justify="center">
                  <Col span={24}>
                    <Row style={{ float: "right" }}>
                      <Col span={5} style={{ paddingRight: 10 }}>
                        <Form.Item className="inline-form-item">
                          <Input
                            value={values.query}
                            placeholder="Search"
                            name="query"
                            className="rounded"
                            prefix={<SearchOutlined />}
                            onChange={handleChange}
                          />{" "}
                        </Form.Item>
                      </Col>

                      <Col span={7} style={{ paddingRight: 10 }}>
                        <Row>
                          <Form.Item
                            className="inline-form-item"
                            label="Status"
                          >
                            <Select
                              defaultValue="all"
                              value={values.status}
                              id="status"
                              showSearch
                              // optionFilterProp="children"
                              // filterOption={(input, option) =>
                              //   option.children
                              //     .toLowerCase()
                              //     .indexOf(input.toLowerCase()) >= 0
                              // }
                              onChange={(e) => {
                                setFieldValue("status", e);
                              }}
                            >
                              <Option value={null}>All</Option>
                              <Option value="pending">Pending</Option>
                              <Option value="rejected">Rejected</Option>
                              <Option value="approved">Approved</Option>
                              <Option value="active">Active</Option>
                              <Option value="completed">Completed</Option>
                              <Option value="hidden">Hidden</Option>
                            </Select>
                          </Form.Item>
                        </Row>
                      </Col>
                      {/* )} */}
                      <Col span={12} style={{ display: "flex" }}>
                        <Form.Item label="Date" className="inline-form-item">
                          <RangePicker
                            dropdownClassName="rounded"
                            className="rounded"
                            value={
                              values.startDate && values.endDate
                                ? [
                                    moment(values.startDate),
                                    moment(values.endDate),
                                  ]
                                : [null, null]
                            }
                            ranges={{
                              Today: [moment(), moment()],
                              Yesterday: [
                                moment().subtract(1, "day"),
                                moment().subtract(1, "day"),
                              ],
                              "This Week": [
                                moment().startOf("week"),
                                moment().endOf("week"),
                              ],
                              "Last Week": [
                                moment().subtract(1, "week").startOf("week"),
                                moment().subtract(1, "week").endOf("week"),
                              ],
                              "This Month": [
                                moment().startOf("month"),
                                moment().endOf("month"),
                              ],
                              "Last Month": [
                                moment().subtract(1, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                              ],
                            }}
                            onChange={(e) => {
                              console.log(e);
                              if (e) {
                                let startDate = moment(e[0]).format(
                                  "YYYY/MM/DD"
                                );
                                let endDate = moment(e[1]).format("YYYY/MM/DD");
                                console.log(startDate, endDate);
                                setFieldValue("startDate", startDate);
                                setFieldValue("endDate", endDate);
                              } else {
                                setFieldValue("startDate", null);
                                setFieldValue("endDate", null);
                              }
                            }}
                          />
                        </Form.Item>

                        <Button
                          htmlType="submit"
                          type="default"
                          style={{ marginRight: 10 }}
                        >
                          Apply
                        </Button>

                        {!isAdmin && (
                          <Button
                            type="default"
                            onClick={() => {
                              navigate("campaigns/create");
                            }}
                          >
                            <PlusOutlined /> create campaign
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <hr style={{ color: "#DEDEDE", opacity: 0.2, marginTop: 30 }} />
              </Col>
              <Col span={6}>
                <div>
                  <h6 style={{ color: "#1B2014", opacity: 0.6, marginTop: 12 }}>
                    {rank} Campaigns
                  </h6>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Filters;
