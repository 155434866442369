import {
  MailFilled,
  PhoneFilled,
  GoogleCircleFilled,
  UserOutlined,
} from '@ant-design/icons';
import { Row, Col, Tag, Skeleton, Avatar } from 'antd';
import React from 'react';

import IG from '../assets/ig.svg';
import FB from '../assets/fb.svg';

const PartnerDetails = ({ partner, isAdmin }) => {
  return (
    <React.Fragment>
      {partner ? (
        <div className="single-partner">
          <Row>
            <Col span={24}>
              <Row gutter={[20, 20]}>
                <Col span={14}>
                  <div className="align-center justify-start">
                    <Avatar
                      size={70}
                      src={partner.profilePicture}
                      shape="square"
                      style={{ marginRight: '1rem' }}
                    />
                    <div>
                      <h4>{partner.name}</h4>
                      <div>
                        <b>
                          {partner.location.formattedAddress
                            ? partner.location.formattedAddress
                            : '-'}
                        </b>
                      </div>
                    </div>
                  </div>

                  <div style={{ padding: '1.6rem 0rem 0rem 0rem' }}>
                    <div className="justify-start align-center">
                      <MailFilled />
                      <p>
                        {partner.email
                          ? partner.email
                          : 'developer@hirepaladin.com'}
                      </p>
                    </div>
                    <div className="justify-start align-center">
                      <PhoneFilled />
                      <p>{partner.phoneNumber ? partner.phoneNumber : '-'}</p>
                    </div>
                    {partner.links ? (
                      <React.Fragment>
                        {partner.links.map((link, i) =>
                          link && link.type === 'website' ? (
                            <div className="justify-start align-center">
                              <GoogleCircleFilled />
                              <p>{link.url}</p>
                            </div>
                          ) : null,
                        )}
                      </React.Fragment>
                    ) : (
                      <div className="justify-start align-center">
                        <GoogleCircleFilled />
                        <p>-</p>
                      </div>
                    )}

                    <React.Fragment>
                      <div className="justify-start align-center">
                        <UserOutlined />
                        <p>{partner.user ? partner.user.email : null}</p>
                      </div>
                    </React.Fragment>
                  </div>
                  <div
                    className="tags"
                    style={{ padding: '1.6rem 0rem 0rem 0rem' }}
                  >
                    {partner.tags.map((tag, i) => (
                      <Tag
                        key={i}
                        style={{
                          backgroundColor: '#EBEBEB',
                          padding: '0.4rem 1rem',
                          color: '#1B2014',
                          borderRadius: 10,
                          fontWeight: 600,
                          border: 0,
                        }}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </div>
                </Col>
                <Col span={10}>
                  <Row justify="end">
                    <div
                      style={{
                        borderRadius: 8,
                        overflow: 'hidden',
                        height: 190,
                        width: 374,
                      }}
                    >
                      <img
                        style={{ objectFit: 'cover' }}
                        height={'100%'}
                        width={'100%'}
                        src={partner.coverPicture}
                        alt={partner.name}
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <div style={{ padding: '1.6rem 0rem' }}>
                    <h5>About</h5>
                    {partner.description ? (
                      <p>{partner.description}</p>
                    ) : (
                      <Col span={24}>
                        <Skeleton></Skeleton>
                      </Col>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="socials">
                <Col span={24}>
                  <h5>Social Media</h5>
                  <Row
                    gutter={[20, 20]}
                    justify="space-between"
                    className="inner-row"
                  >
                    {partner.links.length > 0 ? (
                      <React.Fragment>
                        {partner.links.map((link, i) => (
                          <React.Fragment>
                            {link && link.type === 'facebook' ? (
                              <Col>
                                <span>
                                  <img
                                    className="social-icons"
                                    height={30}
                                    width={30}
                                    src={FB}
                                    alt={'Facebook link'}
                                  />
                                  <a target="_blank" href={link.url}>
                                    {link.url}
                                  </a>
                                </span>
                              </Col>
                            ) : null}
                            {link && link.type === 'instagram' ? (
                              <Col>
                                <span>
                                  <img
                                    className="social-icons"
                                    height={30}
                                    width={30}
                                    alt={'Instagram link'}
                                    src={IG}
                                  />
                                  <a target="_blank" href={link.url}>
                                    {link.url}
                                  </a>
                                </span>
                              </Col>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ) : (
                      <Col>
                        <text>Links Not Available</text>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default PartnerDetails;
