import { Avatar } from "antd";
import React from "react";

const TopPartners = (props) => {
  const { brands } = props;
  // console.log(brands);
  let tempBrands = brands.topBrands ? brands.topBrands.slice(0, 5) : [];
  return (
    <div className="card inner">
      <div>
        <h6>Top Partners</h6>
      </div>
      <div
        className="space-between inner-row border-bottom"
        style={{
          paddingBottom: "0.6rem",
          marginBottom: "1rem",
          fontSize: "0.8rem",
          opacity: 0.6,
        }}
      >
        <div>
          <b>Partner</b>
        </div>
        <div>
          <b>Active Members</b>
        </div>
      </div>
      {brands && brands.topBrands ? (
        <React.Fragment>
          {tempBrands.map((brand, i) => (
            <div className="list-item">
              <div className="space-between align-center">
                <div className="justify-start">
                  <Avatar
                    size={40}
                    shape="square"
                    src={brand.brand.profilePicture}
                  />
                  <div
                    style={{
                      paddingLeft: "0.6rem",
                      paddingRight: "0.6rem",
                      flex: 1,
                    }}
                  >
                    <h6>{brand.brand.name}</h6>
                    <p>University</p>
                  </div>
                </div>
                <div>
                  <p>{brand.memberships}</p>
                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default TopPartners;
